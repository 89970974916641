@use "./config" as mixin;

.col {
  color: mixin.$raisin-black;
  font-family: "Niconne", cursive;
}

body {
  background-color: #eee;
  position: relative;
}
.sticky {
  // position: fixed;
}
header {
  // background-color: mixin.$honeydew;
  background-color: #fff;
  // padding: 0.8rem 1rem;
  color: mixin.$raisin-black;
  // overflow-x: hidden;
  // position: fixed;
  // position: relative;
  width: 100%;
  z-index: 100;
}

.section-title {
  font-size: 1.3rem;
  font-weight: 600;
  width: fit-content;
  margin: 2rem auto 3rem;
  text-transform: uppercase;
  position: relative;
  letter-spacing: 1px;
  font-family: "Noto Sans JP", sans-serif;

  &::after {
    position: absolute;
    content: "";
    width: 130px;
    height: 2px;
    bottom: -15px;
    right: 50%;
    border-radius: 5px;
    transform: translate(50%, -50%);
    background-color: rgb(181, 178, 178);
  }
}

.active {
  color: hsla(230, 8%, 14%, 0.7);
}
