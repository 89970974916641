.about {
  padding: 4rem 2rem;
  height: 100%;

  .section-title {
    font-size: 1.5rem;
    font-weight: 500;
    width: fit-content;
    margin: 2rem auto 3rem;
    text-transform: uppercase;
    position: relative;

    &::after {
      position: absolute;
      content: "";
      width: 130px;
      height: 2px;
      bottom: -15px;
      right: 50%;
      border-radius: 5px;
      transform: translate(50%, -50%);
      background-color: rgb(181, 178, 178);
    }
  }

  .quote {
    margin: 2rem 0;
    letter-spacing: 1px;
    color: #424242;
    line-height: 2.5rem;

    &-right,
    &-left {
      position: relative;
      font-size: 2rem;
      margin: 0 1rem;
    }

    &-text {
      font-size: 1rem;
    }
  }

  &-text {
    font-size: 1rem;
    line-height: 2.5rem;
    letter-spacing: 0.6px;
    color: #424242;
    text-align: justify;
    text-justify: auto;
  }
}

@media screen and (min-width: 475px) {
  .about {
    padding: 4rem 7rem;
    height: 100%;
  }
}
@media screen and (min-width: 1024px) {
  .about {
    padding: 4rem 19rem;
    height: 100%;

    &-text {
      font-size: 1.1rem;
      line-height: 2.5rem;
    }
  }
}
