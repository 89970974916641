.residency {
  //   padding: 4rem 10rem;

  .exhibit {
    margin-top: 5rem;
    width: 70%;
    margin: auto;
  }
}

.exhibition {
  // display: flex;
  // justify-content: center;
  // align-items: center;
  // margin-left: 1rem;
  // margin-right: 1rem;
  // margin-bottom: 2rem;
  width: 50%;
  margin: 4rem auto 5rem;

  p {
    font-weight: 500;
    font-size: 1rem;
    align-self: flex-start;
    text-align: center;
    // margin: 0 0.5rem 1rem 1rem;
    margin: 0 auto 1rem;
    padding: 0 1rem 1rem 1rem;
    line-height: 125%;
    letter-spacing: 1px;
    font-family: "Noto Sans JP", sans-serif;
    width: 50%;
    flex-basis: 1;
    padding-top: 1rem;

    span {
      display: block;
      margin-left: 0.5rem;
      font-size: 0.8rem;
      font-weight: 400;
      letter-spacing: 1px;
    }
  }
}

@media screen and (max-width: 800px) {
  .exhibition {
    // position: relative;
    // z-index: -1;
    width: 100%;

    p {
      width: 100%;
    }
  }
}
