.card {
  width: 25rem;
  height: 450px;
  // height: auto;
  margin: auto;
  position: relative;
  font-family: "Raleway", sans-serif;
  cursor: pointer;
  overflow: hidden;
  transition: all 0.5s ease;
  color: #ffe;

  &_bg {
    width: 100%;
    height: auto;
    transition: all 0.5s ease;
  }

  &_text {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 300px;
    transform: translate(-50%, -50%);
    text-align: center;
    padding: 2rem;

    &_title {
      font-size: 1.3rem;
      font-weight: 500;

      &__line {
        background-color: rgb(219, 219, 219);
        width: 70px;
        height: 3px;
        margin: 1rem auto;
        border-radius: 5px;
      }
    }

    &_desc {
      height: 59px;
      line-height: 1.3rem;
      overflow: hidden;
    }
  }

  &_overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.383);
  }

  &:hover {
    // transform: scale(102%);

    .card_bg {
      transform: scale(105%);
    }
  }
}
