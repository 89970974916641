@use "../../config" as mixin;
// IMG_2851.jpg
.home {
  height: 100vh;
  background-image: url(../../assets/IMG_2851.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  color: mixin.$text-color;
  font-family: "Raleway", sans-serif;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;

  &_text {
    margin-top: -30%;
    text-align: center;
    line-height: 2rem;
    padding: 0 1.5rem;
    font-size: 1.8rem;

    &__header {
      font-size: 1em;
      position: relative;
      width: fit-content;
      text-align: center;
      margin: 0 auto 2rem auto;

      &::after {
        content: " ";
        position: absolute;
        width: 100px;
        height: 2px;
        border-radius: 5px;
        background-color: #fff;
        bottom: -15px;
        left: 50%;
        transform: translateX(-50%);
      }
    }

    &__body {
      font-size: 0.5em;
    }

    .heroImg {
      width: 13rem;
      transform: translateX(55%);

      img {
        width: 100%;
        height: auto;
        object-fit: cover;
      }
    }

    .buttons {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 21rem;
      // transform: translateX(90%);

      a {
        width: 49%;
      }
    }
  }
}

.explore_btn {
  padding: 0.5rem 2rem;
  margin-top: 1rem;
  border: 1px solid white;
  // background-color: #fff;
  background-color: transparent;
  color: white;
  transition: all 0.3s ease-in-out;
  width: 100%;

  &:hover {
    background-color: white;
    color: black;
    border: none;
  }
}

@media screen and (min-width: 700px) {
  .home {
    &_text {
      margin-top: -8%;

      .heroImg {
        width: 16rem;
        transform: translateX(92%);

        img {
          width: 100%;
          height: auto;
          object-fit: cover;
        }
      }

      .buttons {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 21rem;
        transform: translateX(45%);

        a {
          width: 49%;
        }
      }
    }
  }
}

@media screen and (min-width: 1024px) {
  .home {
    &_text {
      margin-top: -2%;
      text-align: center;
      line-height: 2rem;
      padding: 0 1.5rem;
      font-size: 2rem;
      width: auto;

      .heroImg {
        width: 16rem;
        transform: translateX(170%);

        img {
          width: 100%;
          height: auto;
          object-fit: cover;
        }
      }

      .buttons {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 21rem;
        transform: translateX(103%);

        a {
          width: 49%;
        }
      }
    }
  }
}
