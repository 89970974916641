// .swiper {
//   max-width: 50%;
//   height: 50vh;
// }

// .swiper-slide {
//   text-align: center;
//   font-size: 18px;
//   background: #000;
// }

// .swiper-slide img {
// display: block;
// width: auto;
// height: auto;
// max-width: 100%;
// max-height: 100%;
//   -ms-transform: translate(-50%, -50%);
//   -webkit-transform: translate(-50%, -50%);
//   -moz-transform: translate(-50%, -50%);
//   transform: translate(-50%, -50%);
//   position: absolute;
//   left: 50%;
//   top: 50%;
// }

// .swiper {
//   width: 100%;
//   height: 100%;
// }

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 20%;
  height: auto;
  object-fit: cover;
}

.swiper {
  width: 100%;
  height: 300px;
  margin-left: auto;
  margin-right: auto;
  // position: relative;
  // z-index: -1;
}

.swiper-slide {
  background-size: cover;
  background-position: center;
}

.mySwiper2 {
  width: 100%;
  height: 28rem;
}

.mySwiper {
  width: 50%;
  height: 10%;
  box-sizing: border-box;
  padding: 10px 0;
}

.mySwiper .swiper-slide {
  width: 25%;
  height: 100%;
  opacity: 0.4;
}

.mySwiper .swiper-slide-thumb-active {
  opacity: 1;
}

.swiper-slide img {
  display: block;
  width: 100%;
  //   height: 100%;
  height: auto;
  object-fit: cover;
}
