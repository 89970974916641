/* SCSS HEX */
// $bittersweet: #f87060ff;
// $honeydew: #f1faeeff;
// $raisin-black: #212227ff;
// $cadet: #637074ff;
// $cerulean-crayola: #00a5cfff;

/* SCSS HSL */
$bittersweet: hsla(6, 92%, 67%, 1);
$honeydew: hsla(105, 55%, 96%, 1);
$raisin-black: hsla(230, 8%, 14%, 1);
$cadet: hsla(194, 8%, 42%, 1);
$cerulean-crayola: hsla(192, 100%, 41%, 1);

$text-color: $honeydew;
$bg-colour: $raisin-black;
$text-color-dark: $raisin-black;

// /* SCSS RGB */
// $bittersweet: rgba(248, 112, 96, 1);
// $honeydew: rgba(241, 250, 238, 1);
// $raisin-black: rgba(33, 34, 39, 1);
// $cadet: rgba(99, 112, 116, 1);
// $cerulean-crayola: rgba(0, 165, 207, 1);

/* SCSS Gradient */
$gradient-top: linear-gradient(
  0deg,
  #f87060ff,
  #f1faeeff,
  #212227ff,
  #637074ff,
  #00a5cfff
);
$gradient-right: linear-gradient(
  90deg,
  #f87060ff,
  #f1faeeff,
  #212227ff,
  #637074ff,
  #00a5cfff
);
$gradient-bottom: linear-gradient(
  180deg,
  #f87060ff,
  #f1faeeff,
  #212227ff,
  #637074ff,
  #00a5cfff
);
$gradient-left: linear-gradient(
  270deg,
  #f87060ff,
  #f1faeeff,
  #212227ff,
  #637074ff,
  #00a5cfff
);
$gradient-top-right: linear-gradient(
  45deg,
  #f87060ff,
  #f1faeeff,
  #212227ff,
  #637074ff,
  #00a5cfff
);
$gradient-bottom-right: linear-gradient(
  135deg,
  #f87060ff,
  #f1faeeff,
  #212227ff,
  #637074ff,
  #00a5cfff
);
$gradient-top-left: linear-gradient(
  225deg,
  #f87060ff,
  #f1faeeff,
  #212227ff,
  #637074ff,
  #00a5cfff
);
$gradient-bottom-left: linear-gradient(
  315deg,
  #f87060ff,
  #f1faeeff,
  #212227ff,
  #637074ff,
  #00a5cfff
);
$gradient-radial: radial-gradient(
  #f87060ff,
  #f1faeeff,
  #212227ff,
  #637074ff,
  #00a5cfff
);

@mixin media-sm {
  @media screen and (min-width: 776px) {
    @content;
  }
}
