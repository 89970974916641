.footer {
  position: relative;
  bottom: 0;
  background-color: #fff;
  width: 100%;
  height: 70px;
  font-size: larger;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 5rem;

  .social-icons {
    margin-right: 2rem;
    display: flex;
    gap: 1rem;
  }

  &-brand {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .footerImg {
      width: fit-content;
      height: auto;
      img {
        // width: 5rem;
        // height: 100px;
        object-fit: cover;
        object-position: center;
      }
    }

    & p {
      margin-right: 10px;
    }
  }
}

@media screen and (max-width: 700px) {
  .footer {
    padding: 0 3rem;
  }
}
