@import "./config";
@import url("https://fonts.googleapis.com/css2?family=Dancing+Script:wght@400;500;600;700&family=Niconne&family=Permanent+Marker&family=Raleway:wght@200;300;400;500;600;700&family=Special+Elite&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@200;300;400;500;700&display=swap");
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  background-color: $gradient-right;
}

html {
  font-size: 16px;
  font-family: "Raleway", sans-serif;
}

@media (max-width: 900px) {
  html {
    font-size: 15px;
  }
}
@media (max-width: 400px) {
  html {
    font-size: 13px;
  }
}

ul {
  padding: 0;

  li {
    list-style-type: none;
  }
}

a {
  color: inherit;
  text-decoration: none;
}

.container {
  max-width: 1120px;
  margin: auto;
}
