@use "../../config" as mixin;

.worksCategory {
  height: 100%;
  padding: 2rem 1rem;
  background-color: inherit;
  max-width: 1120px;
  margin: auto;

  .section-title {
    // @extend .works_title;
    width: fit-content;
    margin: 1rem auto 5rem;
    color: #323232;
    font-family: "Noto Sans JP";
    letter-spacing: 2px;
    line-height: 2.5rem;
  }
}

.year {
  margin: 4rem 1rem 3rem;
  .year_title {
    margin: 2rem 1rem 2rem 1rem;

    // text-align: center;
    font-size: 25px;
    padding-left: 1rem;
    padding-bottom: 1rem;
    font-weight: 500;
    // text-align: center;
    border-bottom: 1px solid #e5e5e5;
    color: #757575;
    width: 20rem;
  }
}

.galleryContainer {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  // grid-template-columns: repeat(6, 1fr);
  grid-auto-rows: 200px 300px;
  // grid-auto-rows: auto;
  gap: 5px;
  grid-auto-flow: dense;
}

.galleryItem {
  width: 100%;
  height: 100%;
  position: relative;
  // grid-column: span 5;
  // grid-row: span 2;

  .image {
    width: 100%;
    height: 100%;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: 50% 50%;
      cursor: pointer;
      transition: 0.5s ease-in-out;
    }
  }

  .text {
    opacity: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #fff;
    font-size: 17px;
    font-weight: 700;
    pointer-events: none;
    z-index: 5;
    transition: 0.3s ease-in-out;
    backdrop-filter: blur(3px) saturate(1.8);
    text-align: center;
  }

  &:hover {
    .image {
      img {
        transform: scale(1.3);
      }
    }

    .text {
      opacity: 1;
      animation: move-down 0.3s linear;
      padding: 1em;
      width: 100%;
    }
  }
}

@media screen and (max-width: 500px) {
  .galleryContainer {
    grid-template-columns: repeat(2, 1fr);

    .galleryItem {
      // grid-column: span 1;
      // grid-row: span 1;
    }
  }
}

@keyframes move-down {
  0% {
    top: 0%;
  }
  50% {
    top: 35%;
  }
  100% {
    top: 50%;
  }
}
