.Section {
  width: 100%;
  height: 100%;
  //   margin: 5rem 5rem;
  //   display: flex;
  // background-color: #ffe;
  color: #000;
  padding: 3rem 1.8rem;
  //   padding-top: 3rem;
}

.img {
  max-width: 35rem;
  //   height: 35rem;
  margin: auto;
  // margin-right: 2rem;
  //   flex: 1;

  img {
    width: 100%;
    border-radius: 5px;
    // height: auto;
    height: 100%;
    object-fit: cover;
  }
}

.desc {
  text-align: center;
  color: #000;
  padding: 1rem 4rem;
  padding-right: 2rem;
  width: 70%;
  margin: auto;
  margin-bottom: 2rem;

  h2 {
    margin-bottom: 3rem;
    text-align: left;
  }

  p {
    width: 100%;
    margin: auto;
    text-justify: auto;
    letter-spacing: 1px;
    line-height: 2rem;
    text-justify: inter-word;
    text-align: left;
    font-family: "Noto Sans JP", sans-serif;
    font-size: 0.9rem;
    font-weight: 300;
  }
}

@media screen and (max-width: 800px) {
  .section {
    overflow-x: hidden;
    width: 100%;
  }

  .img {
  }
  .desc {
    width: 100%;
    padding: 0;
  }
}
