.works {
  padding: 1rem 1rem;
  // color: #fff;
  padding-top: 2rem;

  &_container {
    display: flex;
    flex-wrap: wrap;
    gap: 3rem;
    padding: 1rem;
  }

  &_title {
    position: relative;
    width: fit-content;
    margin: 3rem auto;
    font-size: 1.5rem;

    h2 {
      font-weight: 500;
      letter-spacing: 0.3rem;
    }

    .line {
      width: 100px;
      height: 3px;
      border-radius: 5px;
      // background-color: #ffe;
      background-color: #747474;
      margin: 0.7rem 0 1rem 1rem;
    }
  }
}
