@use "../../config" as mixin;

.navbar {
  position: relative;
  background-color: #fff;
  // padding: 0.6rem 1rem;
  color: mixin.$raisin-black;
  // overflow-x: hidden;
  // position: fixed;
  width: 100%;
  z-index: 5;
}

.navbarContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 1rem;
  height: 5rem;
}

.navbar_brand {
  // font-family: "Dancing Script", cursive;
  font-family: "Niconne", cursive;
  font-size: 2rem;
  letter-spacing: 3px;
  width: 11%;
  // height: 100%;
  margin-top: 0.5rem;

  img {
    // object-fit: contain;
    object-fit: cover;
    object-position: center;
    width: 100%;
    height: auto;
  }
}

.menu-icon {
  display: none;
  font-size: 1.5rem;
}

.sidebar {
  width: 25rem;
  justify-self: flex-start;
  align-self: center;
  margin: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0;
  // position: relative;
  z-index: 2;

  li {
    list-style-type: none;
  }
}

.nav-item {
  font-family: "Noto Sans JP";
  font-weight: 600;
  font-size: 0.8rem;
  text-transform: uppercase;
  position: relative;
  height: 100%;
  padding: 1rem 0;
  cursor: pointer;
  // transition: all 0.3s ease-in-out;

  // &:hover {
  //   border-bottom: 1px solid rgb(43, 43, 43);
  //   transform: scale(1.1);
  //   transition: all 0.3s ease-in-out;
  // }
}

.sub-menu {
  position: absolute;
  margin-top: 0.2rem;
  padding: 1rem 0;
  background-color: #e5e5e5;
  z-index: 100;
  // overflow: hidden;
  // animation: show 0.5s ease-in-out;

  &:hover {
    display: block;
  }

  li {
    padding: 0.5rem 1rem;
    margin: 0.5rem 0;
    background-color: inherit;

    &:hover {
      filter: brightness(1.2);
    }
  }
}

// @keyframes show {
//   0% {
//   }
//   100% {
//   }
// }

// .nav-item:hover .sub-menu {
//   display: block;
// }

@media screen and (max-width: 800px) {
  .navbar_brand {
    width: 9rem;
  }

  .sidebar {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    background: #fff;
    width: 100%;
    right: 0;
    height: 18rem;
    // visibility: hidden;
    position: absolute;
    top: 105%;
    transition: all 0.5s ease;
    transform: translateY(-130%);
    z-index: 500;
    // opacity: 0;
  }

  .active {
    // background: #1c2237;
    transform: translateY(0);
    background: #fff;
    visibility: visible;
    // left: 0;
    right: 0;
    // right: 0%;
    opacity: 1;
    transition: all 0.3s ease;
    // z-index: 1;
  }

  .nav-item {
    font-size: 0.8rem;
    border-bottom: 1px solid #f5f5f5;
    width: 100%;
    padding: 0;
    padding: 1rem 1rem;

    // a {
    //   display: block;
    //   width: inherit;
    //   height: 100%;

    //   // padding: 1rem 1rem;
    // }
  }

  .menu-icon {
    display: block;
  }
}

// font-family: 'Dancing Script', cursive;
// font-family: 'Permanent Marker', cursive;
// font-family: 'Raleway', sans-serif;
// font-family: 'Special Elite', cursive;
// font-family: 'Noto Sans JP';
