// .grid {
//   display: grid;
//   grid-template-columns: 1fr 1fr;
// }

.exhibition {
  display: flex;
  justify-content: center;
  align-items: center;
  // margin-left: 1rem;
  // margin-right: 1rem;
  margin: auto auto 7rem auto;
  // margin-bottom: 7rem;
  width: 30rem;
  flex-direction: column;

  &:nth-of-type(2n) {
    // flex-direction: row-reverse;
  }

  img {
    border: 5px solid white;
  }

  p {
    font-weight: 500;
    font-size: 1rem;
    align-self: center;
    margin: 0 0.5rem 1rem 1rem;
    padding: 0 1rem 1rem 1rem;
    line-height: 125%;
    letter-spacing: 1px;
    font-family: "Noto Sans JP", sans-serif;

    span {
      display: block;
      margin-left: 0.5rem;
      font-size: 0.8rem;
      font-weight: 400;
      letter-spacing: 1px;
    }
  }
}

@media screen and (max-width: 800px) {
  .exhibition {
    flex-direction: column;

    &:nth-of-type(2n) {
      flex-direction: column;
    }
  }
}
@media screen and (max-width: 500px) {
  .exhibition {
    width: 100%;

    &:nth-of-type(2n) {
      flex-direction: column;
    }
  }
}
