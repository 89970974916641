.tak {
  height: 83vh;
  display: flex;
  align-items: center;
  justify-content: center;
  color: rgb(114, 114, 114);

  h1 {
    font-weight: 400;
  }
}
