.outings {
  height: 80vh;
  text-align: center;
  color: rgb(114, 114, 114);
  padding: 1rem 0;

  h3 {
    color: rgb(31, 31, 31);
  }
  div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    p {
      margin: 2rem 0;
    }
  }
}
